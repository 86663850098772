import Head from 'next/head'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PUBLIC_APP_NAME } from '~/core/constants/env'
import { removeEndSplash } from '~/core/utilities/common'

const HeadMetaTags: FC<{
  title: string
  description?: string
  keywords?: string
  url?: string
  image?: string
  noUseHrefLang?: boolean
  image_alt?: string
  alternateLink?: { url: string; lang: string }
}> = ({
  title,
  description,
  url,
  keywords,
  image,
  alternateLink,
  image_alt
}) => {
  const { i18n } = useTranslation()

  return (
    <Head>
      <title>{title}</title>
      {url && <link rel="canonical" href={url} />}
      <meta content={i18n.language} httpEquiv="content-language" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      <meta itemProp="name" content={title} />
      {image && <meta itemProp="image" content={image} />}

      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}

      {url && <meta property="og:url" content={removeEndSplash(url)} />}
      <meta property="robots" content="noodp,index,follow" />
      <meta property="og:type" content="website" />
      <meta
        property="fb:app_id"
        content={`${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}`}
      />

      {image && <meta name="twitter:card" content={image} />}
      {PUBLIC_APP_NAME && (
        <meta name="twitter:site" content={`@${PUBLIC_APP_NAME}`} />
      )}
      {image && <meta name="twitter:image" content={image} />}
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {alternateLink && (
        <link
          rel="alternate"
          href={`${alternateLink.url}`}
          hrefLang={alternateLink.lang}
        />
      )}
      <meta property="og:site_name" content={title} />
      {image && (
        <>
          <meta property="og:image:secure_url" content={image} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:alt" content={image_alt} />
        </>
      )}
      <link rel="icon" href="/favicon.ico" />
    </Head>
  )
}

export default HeadMetaTags
