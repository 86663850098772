import i18n from 'i18next'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import configuration from '~/configuration'

/**
 * commom resources for en
 */
import en_common from '../../public/locales/en/common.json'
import en_auth from '../../public/locales/en/auth.json'
import en_form from '../../public/locales/en/form.json'
import en_account from '../../public/locales/en/account.json'
import en_notification from '../../public/locales/en/notification.json'
import en_button from '../../public/locales/en/button.json'
import en_dashboard from '../../public/locales/en/dashboard.json'
import en_modal from '../../public/locales/en/modal.json'
/**
 * commom resources for vn
 */
import vn_common from '../../public/locales/vn/common.json'
import vn_auth from '../../public/locales/vn/auth.json'
import vn_form from '../../public/locales/vn/form.json'
import vn_account from '../../public/locales/vn/account.json'
import vn_notification from '../../public/locales/vn/notification.json'
import vn_button from '../../public/locales/vn/button.json'
import vn_dashboard from '../../public/locales/vn/dashboard.json'
import vn_modal from '../../public/locales/vn/modal.json'

const defaultEnResource = {
  common: en_common,
  auth: en_auth,
  form: en_form,
  account: en_account,
  notification: en_notification,
  button: en_button,
  dashboard: en_dashboard,
  modal: en_modal
}
const defaultVnResource = {
  common: vn_common,
  auth: vn_auth,
  form: vn_form,
  account: vn_account,
  notification: vn_notification,
  button: vn_button,
  dashboard: vn_dashboard,
  modal: vn_modal
}

const withTranslateProvider = <F extends object>(
  Component: FC<F>,
  pageResources: {
    en: { [nameSpace: string]: object }
    vn: { [nameSpace: string]: object }
  } = { en: {}, vn: {} }
) => {
  const WithTranslateProvider = <T extends F>(props: T) => {
    const router = useRouter()

    const i18nInstance = useMemo(() => {
      i18n.use(initReactI18next).init({
        resources: {
          en: { ...defaultEnResource, ...pageResources.en },
          vn: { ...defaultVnResource, ...pageResources.vn }
        },
        lng: router.locale,
        fallbackLng: configuration.site.locale,
        interpolation: {
          escapeValue: false
        }
      })
      return i18n
    }, [router.locale])

    return (
      <I18nextProvider i18n={i18nInstance}>
        <Component {...props} />
      </I18nextProvider>
    )
  }
  return WithTranslateProvider
}

export default withTranslateProvider
