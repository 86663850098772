export function isHTML(content: string): boolean {
  if (content && content.match('</[^>]+>')) {
    return true
  }
  return false
}

export const regexEmailValidation =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i

export const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const fetchAndDownloadFile = ({
  file,
  name
}: {
  file: string
  name?: string
}) => {
  if (!file) return

  return fetch(file).then((response) => {
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name || file)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  })
}

export const trimAllEmptySpace = (text: string) => {
  if (!text) return ''
  return text.replace(/^\s+|\s+$/gm, '')
}

export const truncateTextWithDot = (text: string, textLength = 25) => {
  return text?.length > textLength ? `${text.slice(0, textLength)}...` : text
}

export const convertStringArrayToArray = (message: string) => {
  if (!message) return []
  return typeof message === 'string' && message[0] === '['
    ? JSON.parse(message.replace(/\'/g, ''))
    : []
}

export function nonAccentVietnamese(str: string) {
  if (!str || typeof str !== 'string') {
    return ''
  }
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}
export const removeEndSplash = (link: string) => link?.replace(/\/$/g, '')

export const scrollToElement = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

export const removeTags = (string: string) => {
  return string
    .replace(/<[^>]*>/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .replaceAll('&nbsp;', ' ')
    .replace(/[0-9]([0-9])?./g, '')
    .trim()
}
